<template>
  <div class="accounting-already-billed pt-4 pb-8 w-full">

    <vs-row vs-w="12" class="mb-4">
      <vs-col vs-offset="0" vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <h1 >Bereits abgerechnet</h1>
      </vs-col>
      <vs-col vs-offset="6" vs-type="flex" vs-justify="right" vs-align="right" vs-w="6">
        <vs-switch color="success" v-model="displayShortDescriptions" name="displayShortDescriptions" class="mr-2"/>    <label for="displayShortDescriptions"><b>Ausführliche Darstellung</b></label>
      </vs-col>
    </vs-row>


    <h2>Rechnungen ohne Positionszuordnung</h2>
    <p>Alle Rechnungspositionen die diesem Gesamt-Angebot zugewiesen sind.</p>
    <vue-bootstrap4-table
            v-if="invoicesWithoutPositionsData"
            :rows="invoicesWithoutPositionsData"
            :columns="columnsInvoicesWithoutPositionsTable"
            :config="configInvoiceWithoutPositionsTable"
            :classes="{table: 'row-select'}"
            :total-rows="total_rows"
            @on-change-per-page="onChangePerPageInvoiceWithoutPositions"
            class="mt-4 pb-8 mt-8 border-bottom-light">

      <template slot="empty-results">
        Keine Rechnungen gefunden.
      </template>

      <template slot="status" slot-scope="props">
        <span class="vbt-table-td">
         <span v-html="props.row.order.order_status.label"> {{props.row.order.order_status.label}}</span>
       </span>
      </template>

      <template slot="order_date" slot-scope="props">
       <span class="vbt-table-td">
         {{ formatDate(props.row.order.order_date) }}
       </span>
      </template>

      <template slot="amount" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
       </span>
      </template>

      <template slot="product_name" slot-scope="props">
        <span class="vbt-table-td">
          <b>{{ props.row.product_name}}</b><br>
          <small v-html="props.row.short_description" v-if="displayShortDescriptions">{{ props.row.short_description}} </small>
       </span>
      </template>

      <template slot="actions" slot-scope="props">
        <router-link :to="'/invoices/view/'+props.row.order.id">
          <vx-tooltip text="Ansehen" position="left">
            <vs-button color="dark" type="border"
                       icon="visibility" size="small"
            ></vs-button>
          </vx-tooltip>
        </router-link>
      </template>
    </vue-bootstrap4-table>

    <br>
    <br>

    <h2>Rechnungen mit Positionszuordnung</h2>
    <p>Alle Rechnungspositionen die <b>einer Position</b> aus diesem Angebot zugewiesen sind.</p>
    <vue-bootstrap4-table
            v-if="invoicesWithPositionsData"
            :rows="invoicesWithPositionsData"
            :columns="columnsInvoicesWithoutPositionsTable"
            :config="configInvoiceWithPositionsTable"
            :classes="{table: 'row-select'}"
            :total-rows="total_rows"
            @on-change-per-page="onChangePerPageInvoiceWithPositions"
            class="mt-4 pb-8 mt-8 border-bottom-light">

      <template slot="empty-results">
        Keine Rechnungen gefunden.
      </template>

      <template slot="status" slot-scope="props">
        <span class="vbt-table-td">
         <span v-html="props.row.order.order_status.label"> {{props.row.order.order_status.label}}</span>
       </span>
      </template>

      <template slot="order_date" slot-scope="props">
       <span class="vbt-table-td">
         {{ formatDate(props.row.order.order_date) }}
       </span>
      </template>

      <template slot="amount" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
       </span>
      </template>

      <template slot="product_name" slot-scope="props">
        <span class="vbt-table-td">
          <b>{{ props.row.product_name}}</b><br>
          <small v-html="props.row.short_description" v-if="displayShortDescriptions">{{ props.row.short_description}} </small>
       </span>
      </template>

      <template slot="actions" slot-scope="props">
        <router-link :to="'/invoices/view/'+props.row.order.id">
          <vx-tooltip text="Ansehen" position="left">
            <vs-button color="dark" type="border"
                       icon="visibility" size="small"
            ></vs-button>
          </vx-tooltip>
        </router-link>
      </template>
    </vue-bootstrap4-table>

    <br>
    <br>
    <br>

    <h2 >Aktuell in Abrechnung (Noch keine Rechnung)</h2>
    <vue-bootstrap4-table
        v-if="accountingsList"
        :rows="accountingsList"
        :columns="columnsActualAccountingTable"
        :config="configAccountingTable"
        :classes="{table: 'row-select'}"
        :total-rows="total_rows"
        @on-change-per-page="onChangePerPageAccounting"
        class="mt-4 pb-8 mt-8 border-bottom-light">

      <template slot="empty-results">
        Keine Accountings gefunden.
      </template>

      <template slot="status" slot-scope="props">
        <span class="vbt-table-td">
         <span>In Abrechnung</span>
       </span>
      </template>

      <template slot="createdAtSlot" slot-scope="props">
       <span class="vbt-table-td">
         {{ formatDate(props.row.created_at) }} <br><small>von {{props.row.creator.detail.first_name}} {{props.row.creator.detail.last_name}}</small>
       </span>
      </template>

      <template slot="amount" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.amount}} {{getVolumeUnitNameById(props.row.volume_unit)}}
       </span>
      </template>

      <template slot="article_name" slot-scope="props">
        <span class="vbt-table-td">
          {{ props.row.article_name}}<br>
          <small v-html="props.row.short_description" v-if="displayShortDescriptions">{{ props.row.short_description}} </small>
       </span>
      </template>

      <template slot="total_price" slot-scope="props">
          <span class="vbt-table-td">
          {{ props.row.unit_price * props.row.amount}} €
       </span>
      </template>

      <template slot="actions" slot-scope="props">
        <router-link :to="'/accounting/'+props.row.id">
          <vx-tooltip text="Ansehen" position="left">
            <vs-button color="dark" type="border"
                       icon="visibility" size="small"
            ></vs-button>
          </vx-tooltip>
        </router-link>
      </template>
    </vue-bootstrap4-table>

    <vs-row class="mt-8 flex summary" v-if="order">
      <vs-col  vs-w="6">Zusammenfassung</vs-col>
      <vs-col  vs-w="6">
        <div  class="flex mb-3 border-bottom-light">
          <div class="w-1/2">Angeboten: (ohne Optional)</div>
          <div class="w-1/2 text-right">
            <vs-col vs-w="9" v-bind:class="getClassNamebyPercentage(percentagePlannedSalesToInvoiced)" >{{percentageOfferedToInvoiced}}%</vs-col>
            <vs-col vs-w="3">{{order.sum_netto|currency}}</vs-col>
          </div>
        </div>
        <div  class="flex mb-3 border-bottom-light">
          <div class="w-1/2">Planumsätze (gesamt)</div>
          <div class="w-1/2 text-right">
            <vs-col vs-w="9" v-bind:class="getClassNamebyPercentage(percentagePlannedSalesToInvoiced)">{{percentagePlannedSalesToInvoiced}}%</vs-col>
            <vs-col vs-w="3"><span v-if="order.sum_planned_sales">{{order.sum_planned_sales|currency}}</span> <span v-else> - </span></vs-col>
          </div>
        </div>
        <div  class="flex mb-3">
          <div class="w-1/2">Abgerechnet (davon in Abrechnung)</div>
          <div class="w-1/2 text-right">{{sumTotalInvoiced|currency}} <span title="Noch keine Rechnung | Accounting">({{sumTotalAccountings|currency}})</span></div>
        </div>
        <div class="flex">
          <vs-col vs-w="3">Personalaufwände</vs-col>
          <vs-col vs-w="9">{{sumTotalService|currency}}</vs-col>
        </div>
        <div class="flex">
          <vs-col vs-w="3">Fremdleistungen</vs-col>
          <vs-col vs-w="9">{{sumTotalExternalService|currency}}</vs-col>
        </div>
        <div class="flex">
          <vs-col vs-w="3">Material</vs-col>
          <vs-col vs-w="9">{{sumTotalMaterial|currency}}</vs-col>
        </div>

      </vs-col>
    </vs-row>
  </div>
</template>
<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
import ApiService from "../../../../api";
import moment from "moment";
import PriceHelper from "../../../../mixins/helper/price.helper";
import staticOptions from "../../../../mixins/static/options";
import QueryHelper from "../../../../mixins/helper/query.helper";
import * as qs from "qs";

export default {
  name: "OfferAccountingAlreadyBilled",
  components: {
    VueBootstrap4Table
  },
  data() {
    return {
      order: null,
      displayShortDescriptions: false,
      /*
        Invoices with order_item relationship to offer.order_item
       */
      invoicesWithPositionsData: [],
      /*
       Invoices without order_item relationship to offer.order_item  // only offer_id relationship to orders
       */
      invoicesWithoutPositionsData: [],
      columnsInvoicesWithoutPositionsTable: [
        {
          label: "Status",
          name: "order.status",
          slot_name: "status",
          row_text_alignment: 'text-left'
        },
        {
          label: "Rechnungsnummer",
          name: "order.invoice_number",
          sort: true,
          row_text_alignment: 'text-left'
        },
        {
          label: "Rechnungsdatum",
          name: "order.order_date",
          row_text_alignment: 'text-left',
          slot_name: "order_date",
        },
        {
          label: "Positionsbeschreibung",
          name: "product_name",
          slot_name: "product_name",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Menge",
          name: "amount",
          slot_name: "amount",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Einzelpreis",
          name: "unit_price_netto",
          row_text_alignment: 'text-left',
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Gesamtpreis",
          name: "sum_price_netto",
          slot_name: "sum_price_netto",
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Actions",
          slot_name: "actions",
          sort: false,
          showCol: true,
          width: '80px',
        },
      ],
      /*
        Accountings
       */
      accountingsList: [],
      invoicesList: [],
      total_rows: 0,
      columnsActualAccountingTable: [
        {
          label: "Status",
          slot_name: "status",
          row_text_alignment: 'text-left'
        },
        {
          label: "Angelegt am",
          slot_name: "createdAtSlot",
          sort: true,
          row_text_alignment: 'text-left'
        },
        {
          label: "Positionsbeschreibung",
          name: "article_name",
          row_text_alignment: 'text-left',
          slot_name: "article_name",
        },
        {
          label: "Menge",
          name: "amount",
          slot_name: "amount",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Einzelpreis",
          name: "unit_price",
          row_text_alignment: 'text-left',
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Gesamtpreis",
          name: "unit_price",
          slot_name: "total_price",
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
          formatter: PriceHelper.formatCurrency
        },
        {
          label: "Actions",
          slot_name: "actions",
          sort: false,
          showCol: true,
          width: '80px',
        },
      ],
      configAccountingTable: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: false,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 50,
      },
      configInvoiceWithoutPositionsTable: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: false,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 50,
      },
      configInvoiceWithPositionsTable: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: false,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 50,
      }
    }
  },
  computed: {
    sumTotalInvoiced() {
      let sum = 0;
      for (let i = 0; i < this.invoicesWithPositionsData.length; i++) {
        if (this.invoicesWithPositionsData[i].sum_price_netto !== undefined) {
          sum = sum + this.invoicesWithPositionsData[i].sum_price_netto
        }
      }
      for (let i = 0; i < this.invoicesWithoutPositionsData.length; i++) {
        if (this.invoicesWithoutPositionsData[i].sum_price_netto !== undefined) {
          sum = sum + this.invoicesWithoutPositionsData[i].sum_price_netto
        }
      }
      for (let i = 0; i < this.accountingsList.length; i++) {
        if (this.accountingsList[i].amount && this.accountingsList[i].unit_price) {
          sum = sum + (this.accountingsList[i].amount * this.accountingsList[i].unit_price)
        }
      }
      return sum;
    },
    sumTotalAccountings() {
      let sum = 0;
      for (let i = 0; i < this.accountingsList.length; i++) {
        if (this.accountingsList[i].amount && this.accountingsList[i].unit_price) {
          sum = sum + (this.accountingsList[i].amount * this.accountingsList[i].unit_price)
        }
      }
      return sum;
    },
    sumTotalService() {
      var sum = 0;

      if(this.invoicesWithPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithPositionsData.length; i++) {
          if (this.invoicesWithPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithPositionsData[i].article.type.id == 2 || this.invoicesWithPositionsData[i].article.type.parent_id == 2)) {
            sum = sum + this.invoicesWithPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.invoicesWithoutPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithoutPositionsData.length; i++) {
          if (this.invoicesWithoutPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithoutPositionsData[i].article.type.id == 2 || this.invoicesWithoutPositionsData[i].article.type.parent_id == 2)) {
            sum = sum + this.invoicesWithoutPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.accountingsList.length > 0) {
        for (let i = 0; i < this.accountingsList.length; i++) {
          if (this.accountingsList[i].amount && this.accountingsList[i].unit_price && (this.accountingsList[i].article.type.id == 2 || this.accountingsList[i].article.type.parent_id == 2)) {
            sum = sum + (this.accountingsList[i].amount * this.accountingsList[i].unit_price)
          }
        }
      }

      return sum;
    },
    sumTotalExternalService() {
      var sum = 0;

      if(this.invoicesWithPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithPositionsData.length; i++) {
          if (this.invoicesWithPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithPositionsData[i].article.type.id == 3 || this.invoicesWithPositionsData[i].article.type.parent_id == 3)) {
            sum = sum + this.invoicesWithPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.invoicesWithoutPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithoutPositionsData.length; i++) {
          if (this.invoicesWithoutPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithoutPositionsData[i].article.type.id == 3 || this.invoicesWithoutPositionsData[i].article.type.parent_id == 3)) {
            sum = sum + this.invoicesWithoutPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.accountingsList.length > 0) {
        for (let i = 0; i < this.accountingsList.length; i++) {
          if (this.accountingsList[i].amount && this.accountingsList[i].unit_price && (this.accountingsList[i].article.type.id == 3 || this.accountingsList[i].article.type.parent_id == 3)) {
            sum = sum + (this.accountingsList[i].amount * this.accountingsList[i].unit_price)
          }
        }
      }

      return sum;
    },
    sumTotalMaterial() {
      var sum = 0;

      if(this.invoicesWithPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithPositionsData.length; i++) {
          if (this.invoicesWithPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithPositionsData[i].article.type.id == 4 || this.invoicesWithPositionsData[i].article.type.parent_id == 4 || this.invoicesWithPositionsData[i].article.type.id == 5 || this.invoicesWithPositionsData[i].article.type.parent_id == 5)) {
            sum = sum + this.invoicesWithPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.invoicesWithoutPositionsData.length > 0) {
        for (let i = 0; i < this.invoicesWithoutPositionsData.length; i++) {
          if (this.invoicesWithoutPositionsData[i].sum_price_netto !== undefined && (this.invoicesWithoutPositionsData[i].article.type.id == 4 || this.invoicesWithoutPositionsData[i].article.type.parent_id == 4 || this.invoicesWithoutPositionsData[i].article.type.id == 5 || this.invoicesWithoutPositionsData[i].article.type.parent_id == 5)) {
            sum = sum + this.invoicesWithoutPositionsData[i].sum_price_netto
          }
        }
      }
      if(this.accountingsList.length > 0) {
        for (let i = 0; i < this.accountingsList.length; i++) {
          if (this.accountingsList[i].amount && this.accountingsList[i].unit_price && (this.accountingsList[i].article.type.id == 4 || this.accountingsList[i].article.type.parent_id == 4 || this.accountingsList[i].article.type.id == 5 || this.accountingsList[i].article.type.parent_id == 5)) {
            sum = sum + (this.accountingsList[i].amount * this.accountingsList[i].unit_price)
          }
        }
      }

      return sum;
    },
    percentageOfferedToInvoiced(){
      if (this.order && this.sumTotalInvoiced) {
        return  ((this.sumTotalInvoiced / this.order.sum_netto)*100).toFixed(2);
      }
      return 0
    },
    percentagePlannedSalesToInvoiced(){
      if (this.order && this.sumTotalInvoiced) {
        return  ((this.sumTotalInvoiced / this.order.sum_planned_sales )*100).toFixed(2);
      }
      return 0
    },

  },
  created() {
    this.fetchData();
  },
  methods: {
    getClassNamebyPercentage(percent){
      if(percent > 95) {
        return "red";
      } else if(percent > 70) {
        return "orange";
      } else {
        return "green";
      }

    },
    fetchData() {
      this.$vs.loading()
      this.fetchOrderData().then((response) => {
        this.getAccountingsData();
        this.getInvoiceWithoutPositionsData();
        this.getInvoiceWithPositionsData();
        this.$vs.loading.close()
      });

    },
    async fetchOrderData() {
      const id = this.$route.params.id;
      return ApiService.get(`orders/${id}`).then(response => {
          this.order = response.data.result;
      }).catch(response => {
      })
    },
    getAccountingsData() {

      let id = this.order.client_id;
      let offerId = this.order.offer_id;
      let offerItemId = this.order.offer_item_id;

      let queryParams = {
        filters: [],
        limit: 10000,
        offset: 0
      };

      let filterParams = QueryHelper.parseRequestParams(queryParams);


      filterParams.filter.push(
              {
                field: 'order_id',
                value: 'null',
                expression: 'exact',
              },
      );

      var filterGroup = {
        expression: 'group',
        filter: []
      };

      filterGroup.filter.push({
          field: 'offer_id',
          value: this.$route.params.id,
          expression: 'exact',
          operator: 'or',
      },
      {
          field: 'offer_item_id',
          value: [],
          expression: 'exact',
          operator: 'or',
      });

      for (let i = 0; i < this.order.items.length; i++) {
        if (this.order.items[i].id) {
          filterGroup.filter[1].value.push(this.order.items[i].id)
        }
      }

      filterParams.filter.push(filterGroup)
      filterParams.includes =  "article.type";

      ApiService.get('accountings', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.accountingsList = response.data.result;
        this.total_rows = response.data.total;

      })
    },

    getInvoiceWithoutPositionsData() {
      let id = this.order.client_id;
      let offerId = this.order.offer_id;
      let offerItemId = this.order.offer_item_id;

      let queryParams = {
        filters: [],
        limit: 10000,
        offset: 0,
      };

      let filterParams = QueryHelper.parseRequestParams(queryParams);

      filterParams.filter.push(
              {
                field: 'accountings.offer_id',
                value: this.$route.params.id,
                expression: 'exact',
              },
              {
                field: 'accountings.offer_item_id',
                value: 'null',
                expression: 'exact',
              },
      );
      filterParams.includes =  "order.orderStatus,article.type";

      ApiService.get('order_items', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.invoicesWithoutPositionsData = response.data.result;
      })
    },
    getInvoiceWithPositionsData() {
      let id = this.order.client_id;
      let offerId = this.order.offer_id;
      let offerItemId = this.order.offer_item_id;

      let queryParams = {
        filters: [],
        limit: 10000,
        offset: 0,
      };

      let filterParams = QueryHelper.parseRequestParams(queryParams);

    /*  maybe not needed
    filterParams.filter.push(
              {
                field: 'accountings.offer_id',
                value: 'null',
                expression: 'exact',
              }
      );*/

      var filterGroup = {
        expression: 'group',
        filter: []
      };

      filterGroup.filter.push({
        field: 'accountings.offer_item_id',
        value: [],
        expression: 'exact',
        operator: 'or',
      })

      for (let i = 0; i < this.order.items.length; i++) {
        if (this.order.items[i].id) {
          filterGroup.filter[0].value.push(this.order.items[i].id)
        }
      }
      filterParams.filter.push(filterGroup)

      filterParams.includes =  "order.orderStatus,article.type";
      ApiService.get('order_items', {
        params: filterParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.invoicesWithPositionsData = response.data.result;
      })
    },

    formatDate(value) {
      return moment(value).format('DD.MM.YYYY');
    },
    getVolumeUnitNameById(id) {
      const units = staticOptions.units.find(item => item.value === parseInt(id));
      if (units) {
        return units.labelShort
      } else {
        return "unbekannt"
      }
    },
    onChangePerPageAccounting(value) {
      this.configAccountingTable.per_page = value;
    },
    onChangePerPageInvoiceWithoutPositions(value) {
      this.configInvoiceWithoutPositionsTable.per_page = value;
    },
    onChangePerPageInvoiceWithPositions(value) {
      this.configInvoiceWithPositionsTable.per_page = value;
    },
  }
}
</script>

<style lang="scss">
.accounting-already-billed {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .border-bottom-light {
    border-bottom: 1px solid #dccbcb;
  }
  .vs-button {
    margin: 0 auto;
  }
  .summary {
    background-color: #f8f8f8;
    padding: 15px;
    border: 1px solid #dccbcb;
    border-radius: 3px;
    .orange {
      color: #ec7b24;
    }
    .red {
      color: #b31d28;
    }
    .green {
      color: #23a000;
    }
  }
}
</style>
